import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import uk from 'vuetify/es5/locale/uk'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { uk },
    current: 'uk'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#0A7BEB',
        secondary: '#f18a0d',
        accent: '#0f69d9',
        error: '#D32F2F',
        info: '#2196f3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  },
  breakpoint: {
    thresholds: {
      xs: 650,
      sm: 1052,
      md: 1264,
      lg: 1904
    },
    mobileBreakpoint: 650,
    scrollBarWidth: 24
  }
})
