<template>
  <v-app>
    <v-dialog persistent v-model="updateExists" max-width="480" >
      <v-card class="pa-3" rounded>
        <v-card-text
          class="align-center text-center pt-4 pb-6"
          style="font-size: 1.46rem;"
        >
          <v-icon size="110"
                  color="teal">
            mdi-rocket-launch-outline
          </v-icon>
        </v-card-text>
        <v-card-text
          class="py-4"
          style="font-size: 1.4rem; font-weight: bold; text-align: center; color: #2d2d2d">
          Доступний новий контент
        </v-card-text>
        <v-card-text style="text-align: center; font-size: .9rem">
          Ми додали новий контент, щоб бути в курсі змін та актуальних цін натисніть Оновити
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-btn
            color="grey lighten-3"
            @click.stop="refreshApp"
            style="margin: 0 auto;"
            width="320"
            rounded
          >
            Оновити
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <NavDrawer/>
    <v-main>
      <router-view/>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import update from '@/mixins/update'
export default {
  mixins: [update],
  methods: {
    reloadPage () {
      window.location.reload()
    }
  },
  components: {
    NavDrawer: () => import('@/components/NavDrawer'),
    Footer: () => import('@/components/Footer')
  }
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.price-box {
  margin-bottom: 8px;
  padding: 12px;
  .price-box-title {
    color: black;
    padding-top: 2px;
    padding-bottom: 10px;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
  }
  .price-box-value {
    font-size: 1.1rem;
    line-height: 2rem;
    .price-span {
      font-weight: bold;
      font-size: 1.9rem;
    }
  }
}

.dialog-card {
  background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}

.title-card {
  max-width: 1200px;
  width: 100%;
  padding: 50px 50px;
  background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
  border-radius: 10px;
  margin: 80px auto;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}
article {
  max-width: 1200px;
  width: 100%;
  margin: 60px auto;
  display: flex;
  flex-wrap: wrap;
}
.section-wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 60px auto;
  display: flex;
  flex-wrap: wrap;
}

</style>
